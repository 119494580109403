
header {
  background-color: #003262;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  z-index: 1000;
}

#head { 
  position: relative;
  color: white;
  font-size: 18px;
  margin-left: 1.5ch;
  font-weight: 500;
}
#head::before{ 
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: .3s;
}
#head:hover::before{ 
  width: 100%;
}
#head:hover{ 
  cursor: pointer;
}

nav { 
  display: flex;  
  width: 30%;
  justify-content: space-evenly;
  padding: 1.5ch;
}
nav .links{ 
  text-decoration: none;
  position: relative;
  color: rgb(236, 255, 255);
  font-size: 16px;
  font-weight: 400;
}
nav a:hover{ 
  cursor: pointer;
}
nav a::after{ 
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #fff;
  transition: .3s;
  }
nav a:hover::after{
  width: 100%;
}

#about { 
  position: relative;
  width: 30%;
  top: 3cm;
  margin-left: 3cm;  
  text-align: left;
}

#profile{
  border-radius: 50%;
  height: 5cm;
}

.contact-list li { 
  list-style: none;
  margin-top: 5px;
  margin-left: -1cm;
}

.contact-list li a{ 
  /* left: 0; */
  text-decoration: none;
  color: black;
}

#desc{ 
  position: relative;
  margin-top: 3cm;
  /* margin-left: 3cm; */
}
#desc #name{ 
  font-size: 2.5rem;
  font-weight: 500;
  font-family: math;
}
#desc p{ 
  font-size: 17px;
  font-family: math;
}

#research{ 
  position: relative;
  margin-top: 3.5cm;
  margin-left: 3cm;
  text-align: left;
  padding-left: 0;
  font-family: math;
}

#research h1 { 
  font-weight: 500;
  font-size: 2rem;
}

#research h2{ 
  font-size: 23px;
  font-display: swap;
  font-family: Georgia, math;
  font-weight: 400;
}

#projects { 
  position: relative;
  margin-top: 1cm;
  margin-left: 3cm;
  text-align: left;
  padding-left: 0;
  font-family: math;
}
#projects h1 { 
  font-weight: 500;
  font-size: 2rem;
}
.researchImage{
  width: 30%; 
  margin-left: 2em;
  margin-top: -4em;
}

.researchKey{ 
  display: flex;
}

.researchText{ 
  width: 50%;
  font-size: 17px;
  margin-top: 3px;
} 

#projects div{ 
  display: flex;
  flex-wrap: wrap;
  width: 90%; 
}

.projectCard{ 
  flex: 0 0 30%; 
  padding: 15px; 
}

.cardHead{ 
  margin-left: 1ch;
  text-align: left;
}

.cardP{ 
  padding: 15px;
}


#foot{ 
  position:relative;
  bottom: 0;
  left: 0;
  margin-top: 1cm;
  width: 100%;
  background-color: #003262;
  color: white;
  font-size: 14px;
  font-family: math;
}

#foot div{ 
  display: flex; 
  justify-content: center;
  padding: 20px 0px;
  text-align: center;
}

.grow2 {
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
}

.grow2:hover {
  transform: scale(1.05);
}



@media only screen and (max-width: 900px) {
  
  * { 
    flex-wrap: wrap;
  }
  #about{ 
    margin-left: 2em;
    width: 90%;
  }
  #desc{ 
    position: relative;
    margin: 2em;
    margin-top: 6em; 
  }
  #research{ 
    margin-top: -1em;
    margin-left: 2em;   
  }

  .researchKey{ 
    display: block;
  }
  .researchImage{ 
    width: 90%;
    padding: 0;
    text-align: left;
    justify-content: flex-start;
    margin: 0;
  }
  #projects { 
    text-align: left;
    font-family: math;
    margin-left: 2em; 
  }
  .researchText{ 
    width: 100%;
  }

  
  .cardHead{ 
    margin-left: 1ch;
    text-align: left;
  }
  
  .cardP{ 
    padding: 15px;
  }
  
  
  #foot{ 
    position:relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #003262;
    color: white;
    font-family: math;
  }
  
  #foot div{ 
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 20px 0px;
  } 
}

@media (max-width: 1170px) {
  .projectCard {
    flex: 1 0 calc(50%); /* Adjust for smaller screens */
  }
}

@media (max-width: 650px) {
  .projectCard {
    flex: 1 0 calc(70%); /* Adjust for smaller screens */
  }
}
Explanation:


